import { useCallback, useEffect, useMemo, useState } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import useSWR from 'swr';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import SubscriptionPlan from 'src/components/subscription-plan';
import { useCouponCode } from 'src/hooks/use-coupon-code';
import useGeoInfo from 'src/hooks/use-geo-info';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { useIsAuthenticated } from 'src/state/auth';
import { trackEvent, useImpressionTracking } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { TranslationKeyCommon } from 'src/types';
import { getPrice } from 'src/utilities/price-helpers';
import { isSupportedSubscriptionPlan, sortSubscriptionPlans } from 'src/utilities/subscription-plan-helpers';

type SubscriptionPlansProps = {
  subscriptionPlans: SubscriptionPlanStripe[];
  /**
   * Track plan impressions only once or every time it appears on the screen
   * @default false
   */
  trackImpressionOnlyOnce?: boolean;
  /**
   * Optional custom title translation key
   * @default 'subscription_plan__title'
   */
  titleTranslationKey?: TranslationKeyCommon;
};

/**
 * A component containing the subscription plans
 */
export default function SubscriptionPlans({
  titleTranslationKey = 'subscription_plan__title',
  subscriptionPlans: fallbackSubscriptionPlans,
  trackImpressionOnlyOnce = false,
}: SubscriptionPlansProps) {
  const t = useTranslate();
  const sdk = useSdk();
  const isAuthenticated = useIsAuthenticated();
  const { coupon, couponCode } = useCouponCode();
  // by default, yearly interval is always highlighted
  const [selectedSubscriptionInterval, setSelectedSubscriptionInterval] = useState<SubscriptionPlanInterval>(
    SubscriptionPlanInterval.Yearly,
  );

  // Get the location context where the subscription plans are rendered, for the tracking
  const trackingContext = useTrackingContext();

  const { data: geoInfoData } = useGeoInfo();

  // Subscription plan data is different per user or coupon code, so we need to re-fetch it from the API on the client side
  // e.g. A user that has trialed before will see different subscription plans than a user that has not trialed before
  const { data: subscriptionPlansData } = useSWR(
    // The key is based on whether the user is authenticated and the coupon code because the subscription plans can differ based on these factors
    ['subscriptionPlans', isAuthenticated ? 'authenticated' : 'unauthenticated', couponCode].filter(Boolean).join('/'),
    () => sdk.subscriptionPlans({ couponCode }),
    {
      fallbackData: {
        __typename: 'RootQueryType',
        subscriptionPlansStripe: {
          __typename: 'SubscriptionPlansStripe',
          plans: fallbackSubscriptionPlans,
        },
      },
    },
  );

  const subscriptionPlans = useMemo(() => {
    return subscriptionPlansData?.subscriptionPlansStripe.plans
      .filter(isSupportedSubscriptionPlan)
      .sort(sortSubscriptionPlans);
  }, [subscriptionPlansData]);
  const currencyCode = geoInfoData?.currencyCode;
  // Track the user impression of the subscription plans
  const { impressionObserverRef } = useImpressionTracking(
    {
      eventName: 'SubscriptionImpression',
      listName: trackingContext || 'SubscriptionPlans',
      // Use an empty array as a fallback when subscriptionPlans is undefined
      subscriptions: subscriptionPlans || [],
      // Use 'USD' as a fallback when currencyCode is undefined
      currency: currencyCode || 'USD',
    },
    {
      trackImpressionOnlyOnce,
      // Only enable tracking when both subscriptionPlans and currencyCode are defined
      enabled: Boolean(subscriptionPlans && currencyCode),
    },
  );

  const handleSelectPlan = useCallback(
    (subscriptionPlanStripe: SubscriptionPlanStripe) => {
      const price = currencyCode && subscriptionPlanStripe ? getPrice(subscriptionPlanStripe, currencyCode) : undefined;

      // Track the user selection of the subscription plan
      if (subscriptionPlanStripe && price) {
        trackEvent({
          eventName: 'SubscriptionClick',
          listName: trackingContext || 'unknown',
          subscription: subscriptionPlanStripe,
          price,
        });
      }
    },
    [currencyCode, trackingContext],
  );

  useEffect(() => {
    // check if the coupon code is applicable only for one interval type
    const couponHighlightedPlan =
      coupon?.productIdsLimitedTo?.length === 1 &&
      subscriptionPlans?.find((plan) => plan.productId === coupon?.productIdsLimitedTo[0]);
    // if the coupon code is applicable only for one interval type,highlight that plan
    // e.g. if user adds a coupon code that is only applicable for monthly plans,
    // highlight the monthly plan
    if (couponHighlightedPlan) {
      setSelectedSubscriptionInterval(couponHighlightedPlan.interval);
    }
  }, [coupon, subscriptionPlans]);

  /**
   * In order to show "yearly" plan at the top on mobile we render the markup
   * twice. We can’t rely on -reverse flex layout, because, unfortunately, that
   * doesn’t work well with the keyboard navigation – tabbing will go in reverse
   * order, not matching the visual rendering. Instead, we hide the duplicated
   * element when matching the respective breakpoint.
   */
  const reversedSubscriptionPlans = useMemo(() => {
    // reverse mutates the array "in place", so we make a copy
    return subscriptionPlans?.slice().reverse();
  }, [subscriptionPlans]);

  return (
    <div ref={impressionObserverRef} data-test="subscription-plans">
      <div className="p-6">
        <h2 className="dg-text-medium-1 pb-4 text-center">{t(titleTranslationKey)}</h2>
        <ul className="flex flex-col gap-1">
          {[t('subscription_plan__feature_releases'), t('subscription_plan__feature_access')].map((text) => (
            <li
              key={text}
              className="dg-text-regular-6 flex flex-row items-center justify-center gap-1.5 text-white text-opacity-70"
            >
              <CheckmarkIcon className="size-6 text-brandYellowC1" aria-hidden />
              {text}
            </li>
          ))}
        </ul>
      </div>
      {/*
      We limit the width of plans container to the maximum width
      of our modal at 2XL breakpoint, so that plans occupty maxmimum
      available width but not more on that breakpoint.
       */}
      <div className="mx-auto w-auto 2xl:w-[758px]">
        {/**
         * See the note abour duplicated HTML above.
         **/}
        <div className="m-5 mx-auto flex flex-col justify-between p-5 sm:w-2/3 sm:gap-4 lg:hidden">
          {reversedSubscriptionPlans?.map((subscriptionPlan) => (
            <SubscriptionPlan
              subscriptionPlan={subscriptionPlan}
              allSubscriptionPlans={subscriptionPlans}
              selectedSubscriptionInterval={selectedSubscriptionInterval}
              currencyCode={currencyCode}
              onSelect={handleSelectPlan}
              key={subscriptionPlan.productId}
              wholeCardInteractive={true}
            />
          ))}
        </div>

        <div className="m-5 mx-auto hidden flex-col justify-between gap-4 p-5 sm:w-2/3 lg:flex lg:w-4/5 lg:flex-row xl:w-4/5 xl:gap-7 2xl:w-full">
          {subscriptionPlans?.map((subscriptionPlan) => (
            <SubscriptionPlan
              subscriptionPlan={subscriptionPlan}
              allSubscriptionPlans={subscriptionPlans}
              selectedSubscriptionInterval={selectedSubscriptionInterval}
              currencyCode={currencyCode}
              key={subscriptionPlan.productId}
              onSelect={handleSelectPlan}
              wholeCardInteractive={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
