import clsx from 'clsx';
import { useRouter } from 'next/router';
import { SubscriptionPlanInterval, SubscriptionPlanStripe } from 'generated/graphql';
import { TextButtonLink } from 'src/components/buttons/text-button';
import RenderClientOnly from 'src/components/render-client-only';
import Translate from 'src/components/translate';
import useIntl from 'src/hooks/use-intl';
import useSession from 'src/hooks/use-session';
import useTranslate from 'src/hooks/use-translate';
import { useIsAuthenticated } from 'src/state/auth';
import { TranslationKeyCommon } from 'src/types';
import { CurrencyCode } from 'src/utilities/currency-code-helpers';
import { getPrice } from 'src/utilities/price-helpers';
import { getPlanUrl } from 'src/utilities/url-helpers';

type SubscriptionPlanProps = {
  /**
   * The subscription plan to display.
   */
  subscriptionPlan: SubscriptionPlanStripe;
  /**
   * All the subscription plans.
   */
  allSubscriptionPlans?: SubscriptionPlanStripe[];

  /**
   * Pre-selected subscription plan. Normally it’s Yearly, but it can
   * be set by a coupon to a different value (if a coupon applies only to
   * monthly, for example, it will force the highlight of the monthly plan)
   */
  selectedSubscriptionInterval: SubscriptionPlanInterval;
  /**
   * Whether the plan is disabled (e.g. when switching plans is not allowed)
   * @default false
   */
  disabled?: boolean;
  /**
   * The currency code to use for displaying the price.
   */
  currencyCode?: CurrencyCode;
  /**
   * Whether the subscription plan should render its own action button or not
   */
  noActionButton?: boolean;

  /**
   * Callback to be executed when the action button on this plan was clicked
   */
  onSelect?: (subscriptionPlan: SubscriptionPlanStripe) => void;

  /**
   * Is the whole card clickable/tappable?
   */
  wholeCardInteractive?: boolean;
};

// Translation keys for the different subscription plan intervals
const subscriptionPlanTranslationKeys: Record<
  SubscriptionPlanInterval,
  {
    title: TranslationKeyCommon;
    period: TranslationKeyCommon;
    renewal: TranslationKeyCommon;
  }
> = {
  [SubscriptionPlanInterval.Days_7]: {
    title: 'subscription_plan__days_7_title',
    period: 'subscription_plan__days_7_period',
    renewal: 'subscription_plan__days_7_renewal',
  },
  [SubscriptionPlanInterval.Monthly]: {
    title: 'subscription_plan__monthly_title',
    period: 'subscription_plan__monthly_per_period',
    renewal: 'subscription_plan__monthly_renewal',
  },
  [SubscriptionPlanInterval.Yearly]: {
    title: 'subscription_plan__yearly_title',
    period: 'subscription_plan__yearly_per_period',
    renewal: 'subscription_plan__yearly_renewal',
  },
};

function getSubscriptionPlanUrl(interval: SubscriptionPlanInterval, path: string, isAuthenticated: boolean): string {
  const shouldReturnToThisPage = /^\/audio|video|tv\/connected/.test(path);
  const planUrl = getPlanUrl(interval, shouldReturnToThisPage ? path : undefined);
  return isAuthenticated ? planUrl : `/account/signup?redirect=${encodeURIComponent(planUrl)}`;
}

// Translation keys and highlight status for the badge based on the subscription plan interval
function getBadgeProps(
  subscriptionPlanInterval: SubscriptionPlanInterval,
  ticketInterval?: SubscriptionPlanInterval,
  highlight?: boolean,
): { translationKey: TranslationKeyCommon; highlight: boolean } | undefined {
  const isCurrentPlan = ticketInterval === subscriptionPlanInterval;
  const isYearlyPlan = subscriptionPlanInterval === SubscriptionPlanInterval.Yearly;
  // If the plan is the current plan, show the "active" badge and highlight it if it's already selected
  if (isCurrentPlan) return { translationKey: 'subscription_plan__badge_active', highlight: highlight || false };
  // If the plan is a yearly plan, show the "yearly" badge and always highlight it
  if (isYearlyPlan) return { translationKey: 'subscription_plan__badge_yearly', highlight: true };
}

/**
 * A helper function to calculate the savings of a yearly subscription plan,
 * compared to monthly plan, in the given currency.
 */
export function calculateYearlySavings(
  yearlyPlan: SubscriptionPlanStripe,
  monthlyPlan: SubscriptionPlanStripe,
  currencyCode: CurrencyCode,
): {
  amount: number;
  percentage: number;
} {
  // assume 12 monthly payments per year (alternative would be 4-weekly payments)
  const monthlyPaymentsPerYear = 12;
  const yearlyPriceValue = getPrice(yearlyPlan, currencyCode).amount;
  const monthlyPriceValue = getPrice(monthlyPlan, currencyCode).amount;
  const monthlyCostPerYear = (monthlyPriceValue || 0) * monthlyPaymentsPerYear;
  // Calculate the savings by subtracting the yearly price from the monthly cost per year
  // keep in mind that in some cases, with certain promotions, there might be no savings, e.g the difference is negative
  const savings = Math.max(0, monthlyCostPerYear - yearlyPriceValue);

  return {
    amount: savings,
    percentage: Math.round((savings / monthlyCostPerYear) * 100),
  };
}

/**
 * A component to display a subscription plan.
 * Typically used in a list of subscription plans (e.g. when choosing a subscription).
 */
export default function SubscriptionPlan({
  subscriptionPlan,
  currencyCode,
  selectedSubscriptionInterval,
  noActionButton = false,
  onSelect,
  wholeCardInteractive = false,
}: SubscriptionPlanProps) {
  const t = useTranslate();
  const { currencyFormat } = useIntl();
  const session = useSession();
  const { asPath, push } = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const { interval, trialPeriodDays, isPurchasePossible } = subscriptionPlan;
  const translationKeys = subscriptionPlanTranslationKeys[interval];
  const price = currencyCode && getPrice(subscriptionPlan, currencyCode);
  const trialPeriodText: string | undefined =
    trialPeriodDays > 0 ? t('subscription_plan__trial_duration', [trialPeriodDays]) : undefined;
  const renewalText: string | undefined = t(translationKeys.renewal);

  const badgeProps = getBadgeProps(interval, session?.data?.currentUser?.ticket?.interval);

  // define the default values for the badge, renewal and trial period texts
  const badgeText = badgeProps && t(badgeProps.translationKey);

  const isHighlighted = interval === selectedSubscriptionInterval;

  const subscriptionPlanUrl = getSubscriptionPlanUrl(interval, asPath, isAuthenticated);

  return (
    <div
      className={clsx(
        'mb-7 flex min-w-0 flex-1 basis-0 flex-col rounded-md border border-surface-500 p-5',
        wholeCardInteractive && 'cursor-pointer',
        isHighlighted && 'bg-surface-600',
      )}
      data-test={`subscription-plan-option:${interval}`.toLowerCase()}
      onClick={async ({ target }) => {
        // prevent double action when link is clicked
        if (target instanceof HTMLElement && target.parentElement?.matches('a')) return;
        if (!wholeCardInteractive) return;
        await push(subscriptionPlanUrl);
        if (onSelect) {
          onSelect(subscriptionPlan);
        }
      }}
    >
      <h3 className="mb-6 text-white typo-caption-1">{t(translationKeys.title)}</h3>
      <div className="flex min-h-[50px] flex-col justify-center md:min-h-[102px]">
        {/* Optional strikethrough price */}
        {/*
          We add the labels for screen readers ("sr-only" class),
            because <s> is not supported by most screen readers, and for
            these users, the two prices would read the same leading to confusion.
        */}
        {price && price.amountUndiscounted > price.amount && (
          <>
            <span className="sr-only">{t('subscription_plan__original_price')}</span>
            <s className="inline-block lining-nums text-white/55 line-through typo-body-2">
              {currencyFormat(price.amountUndiscounted, price.currency)}
            </s>
          </>
        )}
        {/* Price */}
        {price && (
          <div className="mb-6 lining-nums text-surface-100 typo-body-2">
            {price.amountUndiscounted > price.amount && (
              <span className="sr-only">{t('subscription_plan__price_with_discount')}</span>
            )}
            <Translate
              i18nKey={translationKeys.period}
              values={{
                price: currencyFormat(price.amount, price.currency),
              }}
              components={{
                1: <span className="text-white typo-title-2" />,
              }}
            />
          </div>
        )}
      </div>
      <div className="mt-auto flex min-h-24 flex-col justify-end">
        <p className="leading-normal text-brand-dg typo-caption-2">{badgeText}</p>
        <p className="mb-4 text-xs leading-normal text-surface-100">{renewalText}</p>
        {isPurchasePossible && !noActionButton && (
          <RenderClientOnly>
            <TextButtonLink
              href={getSubscriptionPlanUrl(interval, asPath, isAuthenticated)}
              variation={isHighlighted ? 'primary' : 'secondary'}
              className="lg:min-w-48 lg:self-center"
              data-test={isHighlighted ? 'highlighted-subscription-plans-link' : 'subscription-plans-link'}
              fullWidth
              onClick={() => onSelect && onSelect(subscriptionPlan)}
            >
              {trialPeriodText ?? t('subscription_plan__submit')}
            </TextButtonLink>
          </RenderClientOnly>
        )}
      </div>
    </div>
  );
}
